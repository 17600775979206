<template>
  <sign-page
    title-text="专业技术资格证书管理"
    :request="request"
    table-size="large"
    :column-list="columnList"
    :form-parms-add="formParmsAdd"
    @dataUpdate="dataUpdate"
    :form-parms-update="formParmsUpdate"
    :table-actions-fixed="true"
    :tableActionsWidth="100"
    :need-data-file="true">
  </sign-page>
</template>

<script>
import {
  certificateRequest as request
} from '@/api'

export default {
  computed: {
    formParmsAdd: {
      get () {
        let data = [{
          type: 'input',
          label: '证书名称',
          key: 'title',
          check: {
            required: true
          }
        },
        {
          type: 'select',
          selectDatas: [{key: '员级', label: '员级'}, {key: '助理级', label: '助理级'}, {key: '中级', label: '中级'}, {key: '副高级', label: '副高级'}, {key: '正高级', label: '正高级'}],
          label: '职称级别',
          key: 'mLevel',
          check: {
            required: true
          }
        },
        {
          type: 'select',
          selectDatas: this.$store.getters.selectDatas['certificate_type_list'],
          label: '证书专业',
          key: 'type',
          check: {
            required: true
          }
        }]
        return data
      }
    },
    formParmsUpdate: {
      get () {
        let data = []
        this.formParmsAdd.forEach((item) => {
          let newItem = {}
          for (let key in item) {
            newItem[key] = item[key]
          }
          data.push(newItem)
        })
        return data
      }
    },
    columnList: {
      get () {
        let data = [{
          title: '证书名称',
          sort: true,
          field: 'title'
        },
        {
          title: '职称级别',
          sort: true,
          field: 'mLevel'
        },
        {
          title: '证书专业',
          sort: true,
          field: 'type'
        }]
        return data
      }
    }
  },
  methods: {
    dataUpdate () {
      this.$store.dispatch('loadCertificateList', true)
    }
  },
  data () {
    return {
      request: request
    }
  }
}
</script>
